@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: "NexaBoldTitle",
}

.container {
  width: 1920px;
  height: 1080px;
  margin: 0 auto;
  border: none;
  position: relative;
}

.overlay-container {
  position: relative;
  /* This width and height is just for demo, you can set your own */
  margin: 0 auto;
  width: 1920px;
  height: 1080px;
  border: 2px solid black;  /* Just for visualization */
}

.overlay-content {
  position: absolute;
  top: 0;   /* Adjust these values if you want a different position */
  left: 0; /* Adjust these values if you want a different position */
  width: 100%;  /* Set your desired size */
  height: 100%; /* Set your desired size */
  font-family: "NexaBoldTitle";
}

.component {
  font-size: 18px;
}

p {
  margin: 0px;
  padding: 0px;
}
